'use client'

import { FC, Fragment, useEffect, useRef, useState } from 'react'
import styles from './Pod4TakeACloserLook.module.scss'
import { Pod4TakeACloserLookProps } from './types'
import useIsMobile from 'hooks/useIsMobile'
import cx from 'classnames'
import { CloserLookSlider } from 'components/Phantom/_sections/CloserLookSlider'
import { Button } from 'components/Phantom/Button'
import { useMetricRegion } from 'stores/settings'

export const TakeACloserLookConnected = () => {
	const metric = useMetricRegion()
	return <Pod4TakeACloserLook metric={metric} />
}

export const Pod4TakeACloserLook: FC<Pod4TakeACloserLookProps> = (props) => {
	const [selected, setSelected] = useState(0)
	const isMobile = useIsMobile()
	const { metric = false } = props

	const containerRef = useRef<HTMLDivElement>()

	useEffect(() => {
		if (!containerRef.current) return

		const children = containerRef.current.children
		for (let i = 0; i < children.length; i++) {
			if (i === selected) {
				children[i].classList.add(styles.visible)
			} else {
				children[i].classList.remove(styles.visible)
			}
		}
	}, [selected])

	const texts = ['The Cover', 'The Base', 'The Hub']

	const thickness = metric ? '7 cm' : '3"'
	const weight = metric ? '340 kg' : '750 lbs'
	const dimensions = metric ? '15 cm W x 40 cm L x 40 cm D' : '6" W x 16" L x 16" D'

	const descriptions = [
		'A high-tech layer that wraps around your mattress, similar to a fitted sheet. It facilitates automatic heating and cooling of the Pod and integrates the imperceptible health-grade sensors that track your heart rate, breathing rate, while you sleep.',
		`An adjustable Base that works seamlessly with the Pod to automatically reduce snoring. The Base also offers custom positions for reading, relaxing, and sleeping. Only ${thickness} thick, holds up to ${weight}, and fits inside your existing bed frame, right under your mattress. Exclusive to Pod 4 Ultra.`,
		`The Hub is the central system of the Pod that holds the water for cooling and heating and also the connectivity to the app. It enables Autopilot to take actions in real-time. Only one Hub is needed per Pod and it measures ${dimensions}, fitting next to your nightstand or bed.`,
	]
	const imgs = [
		[
			{
				img: {
					src: 'https://eightsleep.imgix.net/assets/closer_look_1_1_1231.png',
					alt: '',
				},
			},
		],
		[
			{
				img: {
					src: 'https://eightsleep.imgix.net/assets/closer_look_2_1_1216.png',
					alt: '',
				},
			},
			{
				img: {
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/base_full_slats_fullshadow.png',
					alt: '',
				},
			},
			{
				img: {
					src: 'https://eightsleep.imgix.net/assets/closer_look_2_3_1219.png',
					alt: '',
				},
			},
		],
		[
			{
				img: {
					src: 'https://eightsleep.imgix.net/assets/closer_look_3_1_1225.png',
					alt: '',
				},
			},
			{
				img: {
					src: 'https://eightsleep.imgix.net/assets/closer_look_3_2_1225.png',
					alt: '',
				},
			},
		],
	]
	return (
		<div className={styles.container}>
			<div className={styles.content}>
				<div
					ref={containerRef}
					className={styles.mediaContainer}
				>
					{imgs.map((cards, i) => (
						<div
							key={i}
							className={styles.media}
							style={{
								opacity: i === selected ? 1 : 0,
								visibility: i === selected ? 'visible' : 'hidden',
								pointerEvents: i === selected ? 'auto' : 'none',
							}}
						>
							<CloserLookSlider cards={cards} />
						</div>
					))}
				</div>

				<div className={styles.tabDescriptionWrapper}>
					<div className={styles.tabsWrapper}>
						<div className={styles.tabs}>
							{texts.map((text, index) => (
								<Button.Empty
									id={`closer-look-modal-tab-${index}`}
									onClick={() => setSelected(index)}
									className={cx(styles.tab, { [styles.tab_selected]: selected === index })}
									key={text}
								>
									{text}
								</Button.Empty>
							))}
						</div>
					</div>

					<div className={styles.tabDescription}>
						{descriptions.map((description, index) => (
							<Fragment key={description}>
								<p style={{ opacity: index === selected ? 1 : 0 }}>{description}</p>
							</Fragment>
						))}
					</div>
				</div>
			</div>
		</div>
	)
}
