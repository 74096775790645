import { FC, useRef, useState } from 'react'
import styles from './CloserLookSlider.module.scss'
import { CardProps, CloserLookProps, SliderControlsProps } from './types'
import { Button } from 'components/Phantom/Button'
import { Img } from 'components/basic/Img'
import { getDefaultCards } from 'components/Phantom/_sections/CloserLookSlider/utils'
import { Icon } from 'components/Phantom/Icon'
import cx from 'classnames'
import { useSwipe } from 'components/_hooks/useSwipe'
import { mod } from 'components/_utils/mathUtils'

export const CloserLookSlider: FC<CloserLookProps> = (props) => {
	const { cards = getDefaultCards() } = props
	const [currentSlide, setCurrentSlide] = useState(0)
	const containerRef = useRef()

	useSwipe(
		containerRef,
		(direction) => {
			if (direction === 'right') {
				setCurrentSlide((prev) => mod(prev - 1, cards.length))
			} else if (direction === 'left') {
				setCurrentSlide((prev) => mod(prev + 1, cards.length))
			}
		},
		50,
		300
	)

	return (
		<div
			className={styles.container}
			ref={containerRef}
		>
			{cards.length === 1 ? (
				<div className={styles.cardSingle}>
					<Img
						src={cards[0].img.src}
						alt={cards[0].img.alt}
						simple={true}
						className={styles.bg_image}
						objectFit={'contain'}
					/>
				</div>
			) : (
				<>
					<div className={styles.slider_wrapper}>
						<ul className={styles.items}>
							{cards.map((card, i) => (
								<li
									key={i}
									style={{
										opacity: i === currentSlide ? 1 : 0,
									}}
								>
									<Card {...card} />
								</li>
							))}
						</ul>
					</div>

					<SliderControls
						previousSlide={() => setCurrentSlide(Math.max(0, currentSlide - 1))}
						nextSlide={() => setCurrentSlide(Math.min(cards.length - 1, currentSlide + 1))}
						currentSlide={currentSlide}
						numSlides={cards.length}
					/>

					<div className={styles.dots}>
						{cards.map((card, i) => (
							<Button.Empty
								key={card.img.src}
								id={`closer-look-slider-dot-${i}`}
								onClick={() => setCurrentSlide(i)}
								className={cx(styles.dot, i === currentSlide ? styles.dot_selected : '')}
							/>
						))}
					</div>
				</>
			)}
		</div>
	)
}

const SliderControls: FC<SliderControlsProps> = (props) => {
	if (props.numSlides === 1) {
		return null
	}

	const showNext = props.currentSlide < props.numSlides - 1
	const showPrev = props.currentSlide > 0
	return (
		<div className={styles.slider_controls}>
			<div
				style={{
					opacity: showPrev ? 1 : 0,
					visibility: showPrev ? 'visible' : 'hidden',
				}}
			>
				<Button.Empty
					id={'extraordinary-prev'}
					ariaLabel={'Previous Slide'}
					onClick={props.previousSlide}
				>
					<Icon
						name={'ArrowLeftLight'}
						size={'24px'}
					/>
				</Button.Empty>
			</div>
			<div
				style={{
					opacity: showNext ? 1 : 0,
					visibility: showNext ? 'visible' : 'hidden',
				}}
			>
				<Button.Empty
					id={'extraordinary-next'}
					ariaLabel={'Next Slide'}
					onClick={props.nextSlide}
				>
					<Icon
						name={'ArrowRightLight'}
						size={'24px'}
					/>
				</Button.Empty>
			</div>
		</div>
	)
}

const Card: FC<CardProps> = (props) => (
	<div className={styles.card}>
		<Img
			src={props.img.src}
			alt={props.img.alt}
			className={styles.bg_image}
			objectFit={'contain'}
		/>
	</div>
)
