import { FC, PropsWithChildren, useEffect, useRef, useState } from 'react'
import styles from './ThisIsPod4Ultra.module.scss'
import { ThisIsPod4UltraProps } from './types'
import { Icon } from 'components/Phantom/Icon'

import { Button } from 'components/Phantom/Button'
import { useOnClickOutside } from 'components/_hooks/useOnClickOutside'
import { setPageScroll } from 'utils'
import { TakeACloserLookConnected } from 'components/Pod4TakeACloserLook'
import { Video } from 'components/basic/Video'
import { VideoGif } from 'components/basic/VideoGif'
import { useGSAP } from '@gsap/react'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import cx from 'classnames'
interface FullScreenModalProps extends PropsWithChildren {
	closeAction: () => void
	visible: boolean
	aspectRatio?: string
	scrollable?: boolean
}
export const FullScreenModal: FC<FullScreenModalProps> = (props) => {
	const containerRef = useRef<HTMLDivElement>(null)

	useOnClickOutside(containerRef, props.closeAction)

	useEffect(() => {
		setPageScroll(!props.visible)

		if (props.visible) {
			window.sessionStorage.setItem('block-modals', 'true')
		} else {
			window.sessionStorage.removeItem('block-modals')
		}
	}, [props.visible])

	return (
		<div
			className={styles.fullScreen}
			style={{
				opacity: props.visible ? 1 : 0,
				pointerEvents: props.visible ? 'auto' : 'none',
				visibility: props.visible ? 'visible' : 'hidden',
				zIndex: props.visible ? 1300 : -1,
			}}
		>
			<div
				className={cx(styles.full_screen_inner, props.scrollable ? styles.full_screen_inner_scrollable : '')}
				ref={containerRef}
				style={{
					transform: props.visible ? 'scale(1)' : 'scale(0.9)',
					height: props.aspectRatio === '16/9' ? '56.25vw' : '100%',
				}}
			>
				{props.children}
				<Button.Empty
					id={'close-full-screen-modal'}
					onClick={props.closeAction}
					className={styles.closeButton}
				>
					<Icon name={'CloseLight'} />
				</Button.Empty>
			</div>
		</div>
	)
}

const VideoPlayer = () => {
	return (
		<div className={styles.videoContainer}>
			<Video
				src={'https://res.cloudinary.com/eightsleep/video/upload/f_auto,q_auto/thisisPod4video_1336.mp4'}
				className={styles.fullVideo}
				caption={''}
			/>
		</div>
	)
}

export const ThisIsPod4Ultra: FC<ThisIsPod4UltraProps> = (props) => {
	const {} = props
	const [videoVisible, setVideoVisible] = useState(false)
	const [closerLookVisible, setCloserLookVisible] = useState(false)

	useGSAP(() => {
		gsap.registerPlugin(ScrollTrigger)

		ScrollTrigger.create({
			trigger: '#member-hero-video',
			start: 'top 60%',
			end: 'bottom center',
			onEnter: () => {
				const video = document.getElementById('member-hero-video') as HTMLVideoElement
				video.play()
			},
		})
	})

	return (
		<div className={styles.container}>
			<header>
				<p className={styles.new_tag}>New</p>
				<h2>Pod 4 Ultra redefines sleep technology</h2>
				<Button.Plus
					id={'this-is-pod-modal-trigger'}
					aria-haspopup={'dialog'}
					onClick={() => setCloserLookVisible((prev) => !prev)}
				>
					Take a closer look
				</Button.Plus>
			</header>

			<div className={styles.image_container}>
				<VideoGif
					src={'https://res.cloudinary.com/eightsleep/video/upload/f_auto,q_auto/this_is_pod4_new_video.mp4'}
					className={styles.heroImage}
					loop={false}
					autoPlay={false}
					id={'member-hero-video'}
				/>
			</div>

			<FullScreenModal
				closeAction={() => {
					setCloserLookVisible(false)
				}}
				visible={closerLookVisible}
			>
				<TakeACloserLookConnected />
			</FullScreenModal>
			<FullScreenModal
				closeAction={() => {
					setVideoVisible(false)
				}}
				visible={videoVisible}
			>
				<VideoPlayer />
			</FullScreenModal>
		</div>
	)
}
