'use client'

import isChromatic from 'chromatic/isChromatic'
import { srcToImgix } from 'components/basic/Img'
import useIsMobile from 'hooks/useIsMobile'
import { forwardRef, useEffect, useMemo, useRef, VideoHTMLAttributes } from 'react'

interface SourceProps {
	src: string
	type: string
}

interface VideoGifProps extends VideoHTMLAttributes<HTMLVideoElement> {
	sources?: SourceProps[]
	mobileSrc?: string
}

const processVideoPoster = (videoProps: VideoGifProps): string | undefined => {
	if (videoProps.poster === undefined) {
		if (videoProps.src?.includes('res.cloudinary.com') ?? false) {
			return videoProps.src?.replace('f_auto,q_auto', 'f_auto:image,q_auto,so_0')
		}
		return undefined
	}

	return srcToImgix(videoProps.poster)
}

export const VideoGif = forwardRef<HTMLVideoElement, VideoGifProps>((props, ref) => {
	const { sources, ...videoProps } = props
	const videoRef = useRef<HTMLVideoElement>(null)

	const combinedRef = useMemo(() => {
		return (node: HTMLVideoElement) => {
			videoRef.current = node
			if (typeof ref === 'function') {
				ref(node)
			} else if (ref) {
				ref.current = node
			}
		}
	}, [ref])

	const poster = processVideoPoster(videoProps)
	const isMobile = useIsMobile(1024)
	const getSrc = (): string => {
		if (videoProps.mobileSrc === undefined) return videoProps.src ?? ''
		if (isMobile) return videoProps.mobileSrc
		return videoProps.src ?? ''
	}

	const src = getSrc()

	const videoPropsWithoutGifProps = { ...props }
	delete videoPropsWithoutGifProps.sources
	delete videoPropsWithoutGifProps.mobileSrc

	useEffect(() => {
		const video = videoRef.current
		if (!video) return

		const INTERSECTION_THRESHOLD = 0.1 // 10% of the element is visible

		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					// Disable playing in chromatic testing to avoid flaky UI tests
					if (entry.isIntersecting && !isChromatic()) {
						void video.play()
					}
				})
			},
			{
				root: null,
				threshold: INTERSECTION_THRESHOLD,
			}
		)

		observer.observe(video)

		// If the video is already in view, play it
		if (video.getBoundingClientRect().top < window.innerHeight * INTERSECTION_THRESHOLD) {
			void video.play()
		}

		return () => {
			observer.disconnect()
		}
	}, [src])

	return (
		<video
			playsInline
			disablePictureInPicture
			disableRemotePlayback
			preload="none"
			loop
			muted
			ref={combinedRef}
			{...videoPropsWithoutGifProps}
			src={src}
			poster={poster}
			key={src}
		>
			{sources?.map((source, index) => (
				<source
					key={index}
					src={source.src}
					type={source.type}
				/>
			))}
		</video>
	)
})

VideoGif.displayName = 'VideoGif'
